import React, { useEffect } from 'react'

import { useForm } from '../../hooks/useForm'
import Carousels from '../../components/carousel'
import Layout from '../../components/layout'
import Pager from '../../components/pager'
import Seo from '../../components/seo'
// import pictureFeedNews from '../../images/notices.jpeg'
import logo from '../../images/newspaper.png'
import { graphql, Link, navigate } from 'gatsby'
import urlSlug from 'url-slug'
import { NEWS_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'
import moment from 'moment'
import { validateNews } from '../../helpers/validateNews'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'


const newsAll = ({ data, pageContext }) => {
  const [valuesFilter, handleInputChange] = useForm({
    categorySelected: 'Todas',
    organizationSelected: 'Todas'
  })

  const { categorySelected, organizationSelected } = valuesFilter

  const {
    mainNavbar,
    noveltiesCategoriesData,
    organizationalUnits
  } = pageContext

  const {
    allStrapiNovelties: { nodes: novelties },
    strapiInstitution: institution
  } = data
  const objCarousel = {
    visible: true,
    slide: institution.mainCover?.noveltiesCover
  }


  const {
    data: {
      allStrapiOrganizationalUnit: { nodes: organizations }
    }
  } = organizationalUnits

  const page = {
    slug: null,
    ...mainNavbar
  }

  useEffect(() => {
    if (categorySelected.toLowerCase() !== 'todas') {
      navigate(`/${NEWS_GENERAL_ROUTE}/${urlSlug(categorySelected)}/`, {
        replace: false
      })
    }
  }, [categorySelected])

  useEffect(() => {
    if (organizationSelected.toLowerCase() !== 'todas') {
      navigate(`/${urlSlug(organizationSelected)}/${NEWS_GENERAL_ROUTE}/`, {
        replace: false
      })
    }
  }, [organizationSelected])

  return data ? (
    <Layout page={page} logo={page.logo}>
      <Seo title={`Noticias ${mainNavbar.name || ''}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 ? 
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'newsFeed-'} />
          </div>
        : null}
        <div className="container">
          <div className="my-5">
            <div className="d-flex align-items-center row mb-4">
              <div className="col-12 col-md-6">
                <div className=" border-bottom">
                  <h5 className="text-uppercase title-secondary">
                    <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                    Noticias{' '}
                    <span className="font-weight-bold text-main pb-1">UCC</span>
                  </h5>
                </div>
              </div>
              <form className="col-12 col-md-6 mt-separate">
                <div className="d-flex feed-form">
                  <select
                    className="w-100 form-control form-control-sm "
                    onChange={handleInputChange}
                    name="organizationSelected"
                    defaultValue="-1"
                  >
                    <option value="-1" disabled>
                      Por Entidad
                    </option>
                    <option value="Todas">Todas</option>
                    {organizations.map((organization) => (
                      <option
                        value={organization.slug}
                        key={organization.strapiId}
                      >
                        {organization.name}
                      </option>
                    ))}
                  </select>
                  <select
                    className="w-100 form-control form-control-sm"
                    onChange={handleInputChange}
                    name="categorySelected"
                    defaultValue="-1"
                  >
                    <option value="-1" disabled>
                      Por Categoría
                    </option>
                    <option value="Todas">Todas</option>
                    {noveltiesCategoriesData.map((category) => (
                      <option value={category.name} key={category.strapiId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className="row">
              {novelties.length < 1 || validateNews(novelties) ? (
                <h4 className="mt-5 col">
                  No se encontraron noticias de la UCC con la categoría
                  {` "${categorySelected}"`}
                </h4>
              ) : (
                novelties?.map((news) => {
                  if (
                    news.dateTo === null ||
                    moment(news.dateTo, 'YYYY/MM/DD').isSameOrAfter(moment())
                  )
                    return (
                      <div
                        className={`mb-4 col-12 col-md-4 'hightlighted'
                    `}
                        key={`news-all-NodesId-${news.strapiId}`}
                      >
                        <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                          <div
                            className="news-image"
                            style={{
                              backgroundImage: `url(
                            ${news.thumbnail ? news.thumbnail.url : logo} 
                          )`
                            }}
                          ></div>
                          <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                            <h6>
                              <small>
                                {moment(news.date)
                                  .locale('es')
                                  .format('DD-MM-YYYY')}
                              </small>
                            </h6>

                            {/* Etiqueta de la noticia - novelties categories */}
                            {/* <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">                            
                              {news.novelties_categories?.map((noveltieCategory, i) => {
                                if ((news.novelties_categories.length - 1) > i) {
                                  return noveltieCategory?.name + " - "
                                } else {
                                  return noveltieCategory?.name
                                }
                              })}
                            </div> */}

                            <h5 className="font-weight-bold">{news.title}</h5>
                            <div className='noticies-markdown markdown-format'>
                              <p>{news.summary}</p>
                            </div>

                            <Link
                              to={`/noticias/${news.slug}`}
                              className={`btn btn-outline-info mt-auto fit-content text-uppercase 
                                rounded-pill px-4 font-secondary eventuality-button-font-size
                              }`}
                            >
                              VER MÁS
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                })
              )}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  ) : null
}

export default newsAll

export const newListQuery = graphql`
  query($skip: Int, $limit: Int, $date: Date) {
    allStrapiNovelties(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
      filter: { date: { lte: $date }, uccGeneral: { eq: true } }
    ) {
      nodes {
        strapiId
        title
        slug
        summary
        date
        dateTo
        thumbnail {
          url
        }
        novelties_categories {
          name
        }
      }
    }
    strapiInstitution {
      mainCover {
        noveltiesCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
